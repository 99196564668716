html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  background-color: #252525;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.carousel::-webkit-scrollbar {
  display: none;
}

img {
  pointer-events: none;
}
